body{
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;

}
.animate{
  height: 8px;
}
.bx{
  width: 25vw;
}
.hamburger{
  background: #000000;
  max-height: 50px;
  min-height: 50px;
  width: 100vw;
}
.side-cart-item {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #CCCCCC;
  padding: 15px 0;
  position: relative;
}

.side-cart-item .single-cart-item-img {
  height: 109px;
}

.side-cart-item .single-cart-item-img img {
  height: 100%;
}

.side-cart-item .single-cart-item-info {margin-right: 141px;margin-left: 15px;}

.side-cart-item .price {
  font-weight: 600;
  font-size: 16px;
}

.side-cart-item .single-cart-item-info h1 {
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 0;
}

.side-cart-item .single-cart-item-info p {}

.side-cart-item i {
  position: absolute;
  top: 10px;
  right: 0;
}

.hamburgera{
  background: #000000;
  max-height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
}
.ham {
  text-align: start;
}
.ham ul {
  padding-inline-start: 12px;
}
.ham ul li {
  list-style:none;
  color: #fff  ;
  padding-top: 24px;
}

.ham ul a {
  font-family: Space Grotesk;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 30px;
color: #FFFFFF;


}

.ham ul a:hover{
    color:#C7CFDF;
}

.top{
  padding-top: 50px;
  padding-bottom: 140px;
}
.customCard{
  width: 320px;
	height: 400px;
}
.catalouges .singleBestSellingProduct .singleBestSellingProductInfo p{
  color: #242424;
}
.catalouges .singleBestSellingProduct .singleBestSellingProductInfo h5{
  color: #656565;
}
.singleBestSellingProduct {max-width: 306px;margin: auto;margin: 15px 0;}

.singleBestSellingProduct:hover .singleBestSellingProductImg svg{
  fill: #2C3E50;
}

.singleBestSellingProduct .singleBestSellingProductImg {
    position: relative;
    height: 327px;
    overflow: hidden;
    width: 100%;
}

.singleBestSellingProduct .singleBestSellingProductImg svg {
    transition: .3s;
    position: absolute;
    height: 44px;
    width: 44px;
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    fill: #ffffff;
    top: 20px;
    left: 20px;
    z-index: 1;
}

.singleBestSellingProduct .singleBestSellingProductImg img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.singleBestSellingProduct .singleBestSellingProductImg img.secondImg{
  position: absolute;
  bottom: 100%;
  left: 0;
  transition: .4s;
}

.singleBestSellingProduct:hover .singleBestSellingProductImg img.secondImg{
  bottom: 0;
}

.singleBestSellingProduct .singleBestSellingProductInfo {
    margin-top: 5px;
}

.singleBestSellingProduct .singleBestSellingProductInfo p {
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.75px;
    color: #000000;
}

.singleBestSellingProduct .singleBestSellingProductInfo h5 {
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.75px;
    color: #000000;
}
.signIn .row{
  margin: 0;
}

.signIn .row .col-lg-6{
  padding:0;
}
.signInArea {
  padding: 80px;
}

.signInArea h1 {
  font-weight: 300;
  font-size: 36px;
  line-height: 94%;
/* identical to box height, or 34px */
  color: #242424;
  margin-bottom: 15px;
}

.signInArea p {
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
/* identical to box height, or 18px */
  letter-spacing: 0.75px;
  color: #000000;
  margin-bottom: 35px;
}

.signInForm {}

.signInForm .form-control {
  border: 1px solid #CCCCCC;
  height: 48px;
  border-radius: 33px;
  margin-bottom: 18px;
}

.signInForm .actionGrpup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signInForm .actionGrpup .form-group {
  display: flex;
  align-items: center;
}

.signInForm .actionGrpup .form-group input {}

.signInForm .actionGrpup .form-group label {
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
/* identical to box height, or 18px */
  letter-spacing: 0.75px;
  color: #000000;
  margin-left: 10px;
}

.signInForm .actionGrpup a {
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
/* identical to box height, or 18px */
  letter-spacing: 0.75px;
  text-decoration-line: underline;
  color: #656565;
}

.signInArea .signInButton {
  height: 48px;
  border: 1px solid #CCCCCC;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
/* identical to box height, or 112% */
  letter-spacing: 1.25px;
  color: #656565;
  margin-bottom: 20px;
}

.signInArea .signInButton svg {
  margin-right: 15px;
}
.hov{
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .bx{
    width: 40vw;
  }
    }
    
@media screen and (max-width: 576px) {
.bx{
  width: 100vw;
}
  }
  